import { useState, useRef, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useJsApiLoader,
  GoogleMap,
  Autocomplete,
  Marker,
} from '@react-google-maps/api';
import 'overlayscrollbars/overlayscrollbars.css';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useGeolocated } from 'react-geolocated';

import {
  Root,
  FindContainer,
  FindWrapper,
  Form,
  FormItem,
  InputField,
  Input,
  Options,
  MapContainer,
  ToggleList,
  ListContainer,
  ListHeader,
  SelectedFilter,
  List,
  PreloaderContainer,
  Preloader,
  NoResultsContainer,
  NoResultsText,
  NoResultsAction,
} from './Find.style';

import { Button } from '../Button/Button';
import { Checkbox } from '../Checkbox/Checkbox';
import { MapListItem } from '../MapListItem/MapListItem';
import { InfoWindow } from './InfoWindow';

const libraries = ['places', 'geometry'];
const initialCenter = { lat: 35.71142, lng: -83.51986 };
const options = { componentRestrictions: { country: 'us' } };

export const Find = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    libraries,
  });

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 0,
    });

  const prevSearchParamsRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const rootRef = useRef(null);
  const inputRef = useRef(null);
  const mapRef = useRef(null);
  const [center, setCenter] = useState(initialCenter);

  const [inputValue, setInputValue] = useState('');
  const [locationFilterValues, setLocationFilterValues] = useState({
    retail: true,
    barsAndRestaurants: false,
  });

  const [locations, setLocations] = useState([]);
  const [markerIcon, setMarkerIcon] = useState(null);
  const [markerIconActive, setMarkerIconActive] = useState(null);
  const [activeMarkerId, setActiveMarkerId] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowList, setIsShowList] = useState(false);

  const queryParam = searchParams.get('q');
  const locationFilter = `${locationFilterValues.retail ? 'on' : 'off'},${
    locationFilterValues.barsAndRestaurants ? 'on' : 'off'
  }`;

  useEffect(() => {
    if (queryParam) {
      if (prevSearchParamsRef.current !== searchParams) {
        setInputValue(queryParam);
        fetchGeocodeForAddress(queryParam);
      }
    } else {
      if (coords) {
        const { latitude, longitude } = coords;
        const coordinates = {
          latitude,
          longitude,
          territory: 3,
          locationFilter,
        };
        fetchLocations({ coordinates });
      }
    }

    prevSearchParamsRef.current = searchParams;
  }, [searchParams, coords]);

  const handleInputChange = () => {
    const place = inputRef.current.getPlace();
    if (place && place.geometry) {
      const location = place.geometry.location;
      const lat = location.lat();
      const lng = location.lng();

      setCenter({ lat, lng });

      const coordinates = {
        latitude: lat,
        longitude: lng,
        territory: 3,
        locationFilter,
      };

      fetchLocations({ coordinates });

      if (place.formatted_address) {
        setSearchParams(
          {
            q: place.formatted_address,
            locationFilter,
          },
          { preventScrollReset: true }
        );
      }
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    const newLocationFilterValues = {
      ...locationFilterValues,
      [name]: checked,
    };
    const newLocationFilter = `${
      newLocationFilterValues.retail ? 'on' : 'off'
    },${newLocationFilterValues.barsAndRestaurants ? 'on' : 'off'}`;

    setLocationFilterValues(newLocationFilterValues);

    setSearchParams(
      {
        q: inputValue,
        locationFilter: newLocationFilter,
      },
      { preventScrollReset: true }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchGeocodeForAddress(inputValue);
    setSearchParams(
      {
        q: inputValue,
        locationFilter,
      },
      { preventScrollReset: true }
    );
  };

  const handleMapLoad = useCallback((map) => {
    mapRef.current = map;

    const pin = {
      url:
        'data:image/svg+xml;charset=UTF-8,' +
        encodeURIComponent(`
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48px" height="48px">
            <path fill="#DB8F5E"
              d="M8.493 17.829v-.001c-2.257-2.622-3.411-5.342-3.411-7.863a7.054 7.054 0 0 1 2.031-4.952 6.88 6.88 0 0 1 4.888-2.052 6.883 6.883 0 0 1 4.888 2.052 7.057 7.057 0 0 1 2.03 4.952c0 2.521-1.157 5.241-3.41 7.863l-.001.001a20.963 20.963 0 0 1-3.389 3.175.205.205 0 0 1-.23 0 20.967 20.967 0 0 1-3.396-3.175Z" />
            <path fill="#fff"
              d="m12.013 7.2 2.795 2.879-2.795 2.879-2.793-2.879L12.013 7.2ZM8.106 18.085a.014.014 0 0 0 .003.004l.016.019-.02-.023h.001Zm.037.043-.034-.039-.003-.004c-2.271-2.669-3.467-5.524-3.485-8.121.019-1.946.804-3.9 2.164-5.275C8.145 3.313 10.08 2.52 12.001 2.5c1.922.02 3.856.813 5.215 2.189 1.359 1.375 2.145 3.33 2.164 5.276-.019 2.611-1.231 5.482-3.523 8.163l.02-.022-.001.002-.018.021a21.897 21.897 0 0 1-3.48 3.256.956.956 0 0 1-.374.115c-.107 0-.293-.073-.391-.127a21.671 21.671 0 0 1-3.47-3.245l-.018-.02.018.02Zm.737-.556a20.226 20.226 0 0 0 3.124 2.942 20.02 20.02 0 0 0 3.135-2.963l.002-.003.018-.021c2.217-2.561 3.317-5.131 3.299-7.562.015-1.771-.661-3.377-1.897-4.628-1.235-1.25-2.817-1.931-4.56-1.915-1.744-.016-3.325.665-4.561 1.915-1.235 1.25-1.912 2.857-1.897 4.628-.019 2.432 1.079 5.001 3.299 7.563l.037.042.001.002Z" />
          </svg>
        `),
      scaledSize: new window.google.maps.Size(48, 48),
    };
    const pinActive = {
      url:
        'data:image/svg+xml;charset=UTF-8,' +
        encodeURIComponent(`
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48px" height="48px">
            <path fill="#fff"
              d="M12 2.5h.001c1.19.001 2.35.298 3.385.85a7.246 7.246 0 0 1 1.83 1.339c1.359 1.375 2.145 3.33 2.164 5.276-.019 2.611-1.231 5.482-3.523 8.163l.02-.022-.001.002-.018.021a21.897 21.897 0 0 1-3.48 3.256.956.956 0 0 1-.374.115c-.107 0-.293-.073-.391-.127a21.671 21.671 0 0 1-3.47-3.245l-.034-.039-.003-.004c-2.271-2.669-3.467-5.524-3.485-8.121.019-1.946.804-3.9 2.164-5.275a7.26 7.26 0 0 1 1.788-1.317A7.208 7.208 0 0 1 12 2.5ZM8.106 18.085a.014.014 0 0 0 .003.004l.016.019-.02-.023h.001Z" />
            <path fill="#DB8F5E" d="m12.014 6.955 2.936 3.026-2.936 3.025L9.078 9.98l2.936-3.025Z" />
          </svg>
        `),
      scaledSize: new window.google.maps.Size(48, 48),
    };

    setMarkerIcon(pin);
    setMarkerIconActive(pinActive);

    const coordinatesUpdate = () => {
      const center = map.getCenter();
      const bounds = map.getBounds();
      const cornerCoordinates = bounds.getNorthEast();
      const distanceMeters =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          center,
          cornerCoordinates
        );
      const distanceMiles = 0.000621371 * distanceMeters;
      const coordinates = {
        latitude: center.lat(),
        longitude: center.lng(),
        distance: distanceMiles,
        territory: 3,
        locationFilter,
      };

      fetchLocations({ coordinates });
    };

    map.addListener('dragend', coordinatesUpdate);
  }, []);

  const fetchLocations = async ({ coordinates }) => {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${import.meta.env.VITE_MOONSHINE_API}/locator`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ coordinates }),
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const updatedLocations = data?.location;

      if (updatedLocations.length) {
        setLocations(updatedLocations);
        setIsShowList(true);
        const bounds = new window.google.maps.LatLngBounds();
        updatedLocations.forEach((loc) => {
          bounds.extend(new window.google.maps.LatLng(loc.lat, loc.long));
        });
        if (mapRef.current) {
          mapRef.current.fitBounds(bounds);
        }
      } else {
        setLocations([]);
        setIsShowList(false);
        setCenter({ lat: coordinates.latitude, lng: coordinates.longitude });
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchGeocodeForAddress = async (address) => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_MOONSHINE_API}/geocode`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ address }),
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const gps = data?.results?.[0]?.geometry?.location;

      if (gps) {
        setCenter({
          lat: gps.lat,
          lng: gps.lng,
        });

        const coordinates = {
          latitude: gps.lat,
          longitude: gps.lng,
          territory: 3,
          locationFilter,
        };

        fetchLocations({ coordinates });
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  };

  const handleScroll = () => {
    if (rootRef.current) {
      rootRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleClearSearch = () => {
    setInputValue('');
    setSearchParams({}, { preventScrollReset: true });
  };

  // TODO: can show a notifications to improve the UX
  if (!isGeolocationAvailable) {
    console.info('Your browser does not support Geolocation');
  }
  if (!isGeolocationEnabled) {
    console.info('Geolocation is not enabled');
  }

  return (
    <Root ref={rootRef}>
      <FindContainer>
        <FindWrapper>
          <Form onSubmit={handleSubmit}>
            {/* <FormItem className="--first">
              <h1>buy online</h1>
              <Button
                as="a"
                href="https://olesmoky.com/collections/popcorn-sutton"
              >
                Shop Popcorn Sutton
              </Button>
            </FormItem> */}

            <FormItem className="--second">
              <h1>find near you</h1>
              <InputField>
                {isLoaded ? (
                  <Autocomplete
                    className="--autocomplete"
                    onLoad={(autocomplete) => (inputRef.current = autocomplete)}
                    onPlaceChanged={handleInputChange}
                    options={options}
                  >
                    <Input
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      placeholder="Your location"
                    />
                  </Autocomplete>
                ) : (
                  <Input placeholder="Your location" disabled />
                )}
                <Button type="submit" disabled={!inputValue}>
                  Search
                </Button>
              </InputField>
              <Options>
                <Checkbox
                  name="retail"
                  label="Retail"
                  checked={locationFilterValues.retail}
                  onChange={handleCheckboxChange}
                />
                <Checkbox
                  name="barsAndRestaurants"
                  label="Bars & Restaurants"
                  checked={locationFilterValues.barsAndRestaurants}
                  onChange={handleCheckboxChange}
                />
              </Options>
            </FormItem>
          </Form>
        </FindWrapper>
      </FindContainer>

      <MapContainer>
        {isLoaded && (
          <GoogleMap
            id="map"
            mapContainerClassName={`mapContainer${
              isShowList ? ' --isShowList' : ''
            }${!locations.length ? ' --isLocked' : ''}`}
            options={{
              mapId: 'b5744eebd4b2e573',
              disableDefaultUI: true,
              zoomControl: true,
            }}
            center={center}
            zoom={8}
            onLoad={handleMapLoad}
          >
            {locations.map((location, index) => (
              <Marker
                key={index}
                position={{
                  lat: parseFloat(location.lat),
                  lng: parseFloat(location.long),
                }}
                icon={
                  location.num === activeMarkerId
                    ? markerIconActive
                    : markerIcon
                }
                onClick={() => {
                  setSelectedMarker(location);
                  setActiveMarkerId(location.num);
                }}
              />
            ))}

            {selectedMarker && (
              <InfoWindow
                selectedMarker={selectedMarker}
                onClose={() => {
                  setSelectedMarker(null);
                  setActiveMarkerId(null);
                }}
              />
            )}
          </GoogleMap>
        )}

        {isLoading && (
          <PreloaderContainer>
            <Preloader />
          </PreloaderContainer>
        )}

        {!isLoading && !locations.length && (
          <NoResultsContainer>
            <NoResultsText>no results available in this area</NoResultsText>
            <NoResultsAction type="button" onClick={handleClearSearch}>
              Clear search
            </NoResultsAction>
          </NoResultsContainer>
        )}

        {locations.length && (
          <ToggleList type="button" onClick={() => setIsShowList(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={24}
              height={24}
            >
              <path d="M7.5 6a.75.75 0 0 1 .75-.75h12a.75.75 0 1 1 0 1.5h-12A.75.75 0 0 1 7.5 6Zm12.75 5.25h-12a.75.75 0 1 0 0 1.5h12a.75.75 0 0 0 0-1.5Zm0 6h-12a.75.75 0 1 0 0 1.5h12a.75.75 0 0 0 0-1.5ZM4.125 4.875a1.128 1.128 0 0 0-1.039.694A1.123 1.123 0 0 0 5.06 6.625a1.123 1.123 0 0 0-.935-1.75Zm0 6a1.128 1.128 0 0 0-1.039.694 1.123 1.123 0 0 0 1.974 1.056 1.123 1.123 0 0 0-.935-1.75Zm0 6a1.128 1.128 0 0 0-1.039.694 1.123 1.123 0 0 0 1.974 1.056 1.123 1.123 0 0 0-.935-1.75Z" />
            </svg>
          </ToggleList>
        )}

        {isShowList && locations.length && (
          <ListContainer>
            <ListHeader>
              <span className="paragraph-small">Show:</span>
              <SelectedFilter>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width={16}
                  height={16}
                >
                  <path
                    fill="currentColor"
                    d="m14.104 4.354-8 8a.492.492 0 0 1-.545.109.482.482 0 0 1-.162-.109l-3.5-3.5a.5.5 0 1 1 .707-.707l3.146 3.147 7.647-7.647a.498.498 0 0 1 .707 0 .5.5 0 0 1 0 .707Z"
                  />
                </svg>
                <span className="paragraph-small">Retail</span>
              </SelectedFilter>
            </ListHeader>

            <OverlayScrollbarsComponent
              options={{
                scrollbars: { autoHide: 'leave', theme: 'os-theme-light' },
              }}
              defer
            >
              <List>
                {locations.map((loc) => (
                  <MapListItem
                    key={loc.num}
                    location={loc}
                    onMapView={(location) => {
                      setSelectedMarker(location);
                      if (window.innerWidth < 1024) {
                        setIsShowList(false);
                      }
                      handleScroll();
                    }}
                  />
                ))}
              </List>
            </OverlayScrollbarsComponent>

            <ToggleList
              className="--desktop"
              type="button"
              onClick={() => setIsShowList(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
              >
                <path d="M19.281 18.22a.766.766 0 0 1 .219.53.747.747 0 0 1-.75.751.761.761 0 0 1-.531-.22L12 13.061l-6.22 6.22a.747.747 0 0 1-1.061 0 .75.75 0 0 1 0-1.061L10.94 12 4.719 5.781A.75.75 0 1 1 5.78 4.72L12 10.94l6.219-6.22a.75.75 0 0 1 1.062 1.061L13.06 12l6.221 6.22Z" />
              </svg>
            </ToggleList>

            <ToggleList
              className="--mobile"
              type="button"
              onClick={() => setIsShowList(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
              >
                <path d="M21.461 4.658a.754.754 0 0 0-.643-.136l-5.731 1.433-5.751-2.876a.755.755 0 0 0-.518-.057l-6 1.5a.752.752 0 0 0-.568.728v13.5a.752.752 0 0 0 .932.728l5.731-1.433 5.751 2.876a.757.757 0 0 0 .518.057l6-1.5a.752.752 0 0 0 .568-.728V5.25a.749.749 0 0 0-.289-.592ZM9.75 4.963l4.5 2.25v11.824l-4.5-2.25V4.963Zm-6 .873 4.5-1.125v11.953l-4.5 1.125V5.836Zm16.5 12.328-4.5 1.125V7.336l4.5-1.125v11.953Z" />
              </svg>
            </ToggleList>
          </ListContainer>
        )}
      </MapContainer>
    </Root>
  );
};
